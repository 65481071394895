<template>
  <el-dialog title="家庭地址" :visible.sync="visible" :width="dialogWidth" :before-close="visibleClose" :close-on-click-modal="false">
    <div class="dialog-content">
      <!--        {{addressData}}-->
      <el-form ref="myForm" :model="myForm" :rules="rules" label-width="140px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="选择地区" prop="addressArray">
              <AreaSelect ref="area" v-model="myForm.addressArray" class="w300px" :edit-address="address" :edit-address-id="addressId" @addressChange="addressChange" />
            </el-form-item>
            <el-form-item label="详细地址" prop="detaAddress">
              <el-autocomplete
                v-model="myForm.detaAddress"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                clearable
                class="w300px"
                popper-class="my-autocomplete"
                @select="handleSelectItem"
              >
                <template slot-scope="{ item }">
                  <div class="name">{{ item.name }}</div>
                  <span class="addr">{{ item.cityname }}{{ item.adname }}{{ item.address }}</span>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="门牌号" prop="roomNo">
              <el-input class="w300px" v-model="myForm.roomNo" placeholder="请输入门牌号, 例: 1幢1单元101" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="具体定位">
              <div id="myMap" class="myMap" />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="bottom-btn">
          <el-button type="primary" plain @click="visibleClose">取 消</el-button>
          <el-button type="primary" @click="handlerConfirm">确 定</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import { getUserExis, getDefaultAddress } from '@/api/common'
import AMap from 'AMap' // 引入高德地图
import AreaSelect from '@/components/AreaSelect/areaSelect.vue'
export default {
  components: {
    AreaSelect
  },
  props: {
    visible: Boolean,
    addressData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      myForm: {
        addressArray: [],
        detaAddress: '',
        roomNo: '',
        lat: '',
        lon: ''
      },
      dialogWidth: '980px',
      rules: {
        detaAddress: [{ required: true, message: '请填写详细地址', trigger: 'change' }],
        addressArray: [{ required: true, message: '请选择订单地址', trigger: 'change' }]
      },
      rules1: {
        apptTime: [{ required: true, message: '请选择预约时间', trigger: 'blur' }],
        serviceCombosId: [{ required: true, message: '请选择套餐类型', trigger: 'change' }]
      },
      address: ['--请选择--'], // 地址集合
      addressId: [], // 地址ID集合
      // 地图
      amap: null, // 地图
      geocoder: null, // 定位
      marker: null, // 标记点
      // circle: null, // 范围圈
      point: [] // 标记点经纬度 [lng, lat]
    }
  },
  watch: {
    addressData: {
      handler(val) {
        console.log(val)
        const { region, homeAdder } = val
        this.myForm.detaAddress = homeAdder
        this.myForm.roomNo = region
      },
      deep: true
    }
  },
  mounted() {
    this.initAddress() //初始化先执行显示地图
    console.log(this.addressData, '<<<<')
    if (this.addressData !== null) { //如果有地址回显地图
      const { homeAdder, lon, lat, roomNo } = this.addressData
      const params = {
        name: homeAdder,
        location: lon + ',' + lat
      }
      this.getAddress(`${lon}, ${lat}`)
      setTimeout(() => {
        this.handleSelectItem(params)
      }, 300)
      this.myForm.roomNo = roomNo
    }
  },
  methods: {
    /* 确定选择*/
    handlerConfirm() {
      this.$emit('postData', this.myForm)
      this.$emit('update:visible', false)
    },
    async querySearch1(queryString, cb) {
      if (queryString != '') {
        const callBackArr = []
        const result = await getUserExis({
          keyWord: queryString
        })
        result.data.forEach(element => {
          callBackArr.push(element)
        })
        if (callBackArr.length == 0) {
          cb([{ userName: '暂无数据' }])
        } else {
          cb(callBackArr)
        }
      }
    },
    async initAddress() {
      const result = await getDefaultAddress()
      if (result.state === 0) {
        // const { area, street, province, city = null, aNumber, cNumber, pNumber, sNumber } = result.data
        const { area, province, city = null, aNumber, cNumber, pNumber } = result.data
        console.log(area, province, city)
        if (city === null) {
          city = province
        }
        const array = []
        const arrayId = []
        array.push(province, city, area)
        this.address = array
        console.log(array, 'initArray')
        this.myForm.addressArray = array
        arrayId.push(pNumber, cNumber, aNumber)
        this.addressId = arrayId
        this.getInfo(`${province}${city}${area}`)
      } else {
        this.getInfo('重庆市')
      }
    },
    // 通知父组件关闭弹窗
    visibleClose(val) {
      this.$emit('update:visible', false)
    },
    // 通知父组件更新页面
    pageUpdate() {
      this.$emit('pageRefresh', 'true', true)
    },
    /**
     * 获取初始定位
     * 根据输入的地址获取初始经纬度
     */
    getInfo(address) {
      AMap.plugin(['AMap.Geocoder'], () => {
        this.geocoder = new AMap.Geocoder({})
      })
      this.geocoder.getLocation(address, (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
          this.point[0] = result.geocodes[0].location.lng
          this.point[1] = result.geocodes[0].location.lat
          this.mapInit()
        }
      })
    },
    // 初始化地图
    mapInit() {
      this.amap = new AMap.Map('myMap', {
        resizeEnable: true,
        zoom: 15
      })
      this.amap.setCenter(this.point)
      this.addMark(this.amap, this.point)
      // this.addCircle(this.amap)
    },
    // 添加标记
    addMark(map, points) {
      this.marker = new AMap.Marker({
        map: map,
        position: map.getCenter(),
        draggable: true // 允许拖动
        // raiseOnDrag: true
      })
      map.setFitView() // 根据地图上添加的覆盖物分布情况，自动缩放地图到合适的视野级别
      // 拖动更改
      this.marker.on('dragend', async e => {
        const position = this.marker.getPosition()
        // 更新坐标
        const result = await this.getAddress(`${position.lng}, ${position.lat}`)
        this.point = [position.lng, position.lat]
        this.myForm.lon = this.point[0]
        this.myForm.lat = this.point[1]
        this.marker.setPosition(this.point)
        map.setCenter(this.point)
        map.setFitView()
      })
    },
    // 选择地址
    addressChange(val, addressId) {
      const str = val.join('')
      this.myForm.addressArray = val
      this.address = val
      this.addressId = addressId
      // 重绘地图
      this.getInfo(str)
    },
    async querySearch(queryString, cb) {
      if (queryString != '') {
        const callBackArr = []
        const result = await axios({
          method: 'get',
          url: 'https://restapi.amap.com/v3/place/text?parameters',
          params: {
            key: 'a40dccd53b4b7be31839cebd738630ac',
            city: '重庆',
            keywords: this.myForm.detaAddress
          }
        })
        result.data.pois.forEach(element => {
          callBackArr.push(element)
        })
        if (callBackArr.length == 0) {
          cb([{ value: '暂无数据', price: '暂无数据' }])
        } else {
          cb(callBackArr)
        }
      }
    },
    handleSelectItem(item) {
      this.myForm.detaAddress = item.name
      this.handleSelect(item.location)
    },
    async handleSelect(data) {
      const result = await axios({
        method: 'get',
        url: 'https://restapi.amap.com/v3/geocode/regeo?parameters',
        params: {
          key: 'a40dccd53b4b7be31839cebd738630ac',
          location: data
        }
      })
      // this.myForm.detaAddress = name
      const arrId = []
      arrId.push(500000, 500100, parseInt(result.data.regeocode.addressComponent.adcode))
      this.addressId = arrId
      const arr = []
      arr.push(result.data.regeocode.addressComponent.province, result.data.regeocode.addressComponent.province, result.data.regeocode.addressComponent.district)
      this.address = arr
      console.log(arr, 'chooseAddress---')
      this.myForm.addressArray = arr // 选择地区之后赋值
      // this.$bus.$emit('getAddress')
      this.point = data.split(',').map(Number)
      this.myForm.lon = this.point[0]
      this.myForm.lat = this.point[1]
      this.amap.remove(this.marker)
      this.amap.setCenter(this.point)
      this.addMark(this.amap, this.point)
    },
    async getAddress(location) {
      const result = await axios({
        method: 'get',
        url: 'https://restapi.amap.com/v3/geocode/regeo',
        params: {
          key: 'a40dccd53b4b7be31839cebd738630ac',
          location
        }
      })
      if (result.data.info === 'OK') {
        const { province, district, adcode } = result.data.regeocode.addressComponent
        const array = []
        array.push(province)
        array.push(province)
        array.push(district)
        this.address = array
        const arrayId = []
        arrayId.push(500000, 500100, adcode)
        this.addressId = arrayId
        this.myForm.addressArray = array
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .w300px {
        width: 300px;
    }
.dialog-content {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
  display: flex;
  padding: 20px;
  position: relative;
  width: 100%;
  .el-form {
    width: 100%;
    .el-row {
      width: 100%;
    }
  }
  .search-box {
    position: absolute;
    background: #ffffff;
    border: 1px solid #dfe4ed;
    margin-top: 12px;
    z-index: 2;
    .popper__arrow {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 6px;
      filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
      top: -6px;
      left: 10%;
      margin-right: 3px;
      border-top-width: 0;
      border-bottom-color: #dfe4ed;
      &::after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        content: ' ';
        border-width: 6px;
        top: 1px;
        margin-left: -6px;
        border-top-width: 0;
        border-bottom-color: #fff;
      }
    }
    .tempalte {
      margin-top: 12px;
      max-height: 200px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        /**/
      }
      &::-webkit-scrollbar-track {
        // background: rgb(239, 239, 239);
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: #eee;
        border-radius: 10px;
      }
      .search-template {
        height: 34px;
        padding: 0 10px;
        color: #606266;
        cursor: pointer;
        span {
          margin-right: 5px;
        }
        &:hover {
          color: rgba(5, 190, 0, 1);
          background: rgba(5, 190, 0, 0.07);
        }
      }
    }
  }
  .myMap {
    height: 300px;
  }
  .bottom-btn {
    text-align: center;
  }
}
.my-autocompconste {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
.confirm {
  display: block;
  padding-left: 20px;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>
