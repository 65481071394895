<template>
    <div class="mainContainer" @click="isShow = true">
        <span>{{ address.join('/') }}</span>
        <i :class="[isShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down']" style="margin: 12px -5px 10px 15px; float: right"></i>
        <div class="list-wrap">
            <div class="list" v-if="isShow">
                <i class="el-icon-circle-close" style="font-size: 18px; position: absolute; right: 10px; z-index: 100" @click.stop="isShow = false"></i>
                <el-tabs v-model="activeName" type="card">
                    <!-- 省级 -->
                    <el-tab-pane :label="provinceName" name="province">
                        <el-row>
                            <el-col
                                class="item"
                                :span="6"
                                v-for="(province, index) in provinceList"
                                :key="province.districtId"
                                :style="{ color: index === currentProvince ? '#3fcf89' : '' }"
                            >
                                <div @click="choose('province', 'city', 0, index, province.districtName, province.districtId)">
                                    {{ province.districtName }}
                                </div>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- 市级 -->
                    <el-tab-pane :label="cityName" name="city" v-if="cityList && cityList.length > 0">
                        <el-row>
                            <el-col class="item" :span="6" v-for="(city, index) in cityList" :key="city.districtId" :style="{ color: index === currentCity ? '#3fcf89' : '' }">
                                <div @click="choose('city', 'county', 1, index, city.districtName, city.districtId)">
                                    {{ city.districtName }}
                                </div>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- 区县 -->
                    <el-tab-pane :label="countyName" name="county" v-if="countyList && countyList.length > 0">
                        <el-row>
                            <el-col
                                class="item"
                                :span="6"
                                v-for="(county, index) in countyList"
                                :key="county.districtId"
                                :style="{ color: index === currentCounty ? '#3fcf89' : '' }"
                            >
                                <div @click="choose('county', '', 2, index, county.districtName, county.districtId)">{{ county.districtName }}</div>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- 城镇/街道 -->
                    <!-- <el-tab-pane :label="townName" name="town" v-if="townList && townList.length > 0">
                      <el-row>
                          <el-col 
                              class="item" 
                              :span="6" 
                              v-for="(town,index) in townList" 
                              :key="town.districtId" 
                              :style="{color: index === currentTown ? '#3fcf89':''}">
                                  <div @click="choose('town', 'community', 3, index,town.districtName,town.districtId)">{{ town.districtName }}</div>
                          </el-col>
                      </el-row>
                  </el-tab-pane> -->
                    <!-- 社区 -->
                    <!-- <el-tab-pane :label="communityName" name="community" v-if="communityList && communityList.length > 0">
                      <el-row>
                          <el-col 
                              class="item" 
                              :span="6" 
                              v-for="(community,index) in communityList" 
                              :key="community.districtId" 
                              :style="{color: index === currentCommunity ? '#3fcf89':''}">
                                  <div @click="choose('community', '', 4, index,community.districtName,community.districtId)">{{ community.districtName }}</div>
                          </el-col>
                      </el-row>
                  </el-tab-pane> -->
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { getAddress } from '@/api/common'
import { deepCopy } from '@/utils'
export default {
    // 地区选择
    name: 'areaSelect',
    props: {
        editAddress: Array,
        editAddressId: Array
    },
    watch: {
        editAddress: {
            handler(val) {
                if (val) {
                    this.address = deepCopy(this.editAddress)
                    this.addressId = deepCopy(this.editAddressId)
                    this.addressId.forEach((el, index) => {
                        const type = this.getType(index)
                        this.parentId = index ? this.addressId[index - 1] : null
                        this.getAddress(type, true, this.address[index])
                    })
                }
                if (this.editAddressId[2]) {
                    getAddress({ parentId: this.editAddressId[2] }).then(res => {
                        this.activeName = 'county'
                    })
                }
            }
        }
    },
    data() {
        return {
            isShow: false,
            parentId: null,
            address: ['--请选择--'],
            addressId: [], // 所选地区ID
            districtSearch: '',
            activeName: 'province',
            provinceList: [], // 省级list
            provinceName: '请选择', // 省级名称
            currentProvince: null, //当前省级索引
            cityList: [], // 市级list
            cityName: '请选择', // 市级名称
            currentCity: null, //当前市级索引
            countyList: [], // 区县list
            countyName: '请选择', // 区县名称
            currentCounty: null, // 当前区县索引
            townList: [], // 城镇/街道list
            townName: '请选择', // 城镇/街道名称
            currentTown: null, // 当前城镇/街道索引
            communityList: [], // 社区列表
            communityName: '请选择', // 社区名称
            currentCommunity: null // 当前社区索引
        }
    },
    mounted() {
        this.getAddress('province', false, null)
    },
    methods: {
        /**
         * 获取地址
         * type：类型--区分 province省级 city市级 county区级  town城镇/街道  community社区
         * edit：是否是编辑 用来判断回显数据
         * chooseName：编辑时选中要显示的地址名称
         */
        getAddress(type, edit, chooseName) {
            getAddress({ parentId: this.parentId }).then(res => {
                // 清除数据
                let currentIndex = `current${type.slice(0, 1).toUpperCase()}${type.slice(1, type.length)}`
                this[currentIndex] = null
                let currentName = `${type}Name`
                this[currentName] = '请选择'
                // 获取对应列表
                let list = `${type}List`
                this[list] = res.data
                if (edit) {
                    let index = this[list].findIndex(item => {
                        return item.districtName === chooseName
                    })
                    this[currentIndex] = index
                    this[currentName] = chooseName
                }
            })
        },
        /**
         * 地区选择
         * type：地区层级 province省级 city市级 county区级
         * nextType：下一级层级
         * level: 层级
         * index：选中地区的当前索引
         * name: 选中的地区名称
         * districtId: 选中地区的行政编号
         */
        choose(type, nextType, level, index, name, districtId) {
            let currentName = `${type}Name`
            let currentIndex = `current${type.slice(0, 1).toUpperCase()}${type.slice(1, type.length)}`
            this[currentName] = name
            this[currentIndex] = index
            this.parentId = districtId
            this.address[level] = name
            this.addressId[level] = districtId
            this.address = this.address.slice(0, level + 1) // 清空之前的地址
            this.addressId = this.addressId.slice(0, level + 1)
            if (nextType) {
                this.getAddress(nextType)
                this.activeName = nextType
            }
            this.$emit('addressChange', this.address, this.addressId, true)
        },
        // 关闭选择
        close() {
            this.isShow = false
        },
        // 获取类型
        getType(index) {
            switch (index) {
                case 0:
                    return 'province'
                    break
                case 1:
                    return 'city'
                    break
                case 2:
                    return 'county'
                    break
                case 3:
                    return 'town'
                    break
                case 4:
                    return 'community'
                    break
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.mainContainer {
    min-width: 200px;
    background: #f5f5f5;
    // height: 36px;
    // line-height: 36px;
    padding: 0 15px;
    display: inline-block;
    border-radius: 4px;
    box-sizing: border-box;
    color: #606266;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    .list-wrap {
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 200;
        padding-bottom: 20px;
    }
    .list {
        // display: none;
        width: 600px;
        background: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 10px 20px;
        border-radius: 2px;
        .item {
            // width: 100px;
        }
    }
}
</style>
